import {type Key} from "@avvoka/shared";

let lastKeyCompositionStatus = false

export const onKeyDownComposition = (evt: KeyboardEvent) => {
  lastKeyCompositionStatus = evt.isComposing
}
export const shouldIgnoreKey = (evt: KeyboardEvent & { aKeyEvent?: boolean }) =>
  lastKeyCompositionStatus ||
  evt !== Object(evt) ||
  evt.isComposing ||
  evt.aKeyEvent === true

export const isKeyCode = (evt: KeyboardEvent, keyCodes: Key[]) =>
  shouldIgnoreKey(evt) ? false : keyCodes.includes(evt.code as Key)
